<template>
  <Modal :dismissible="true" :with-footer="true">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('orders.list.details.id')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{order.getId()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('orders.list.details.status')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          <Status :status="order.getStatus()"/>
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('orders.list.details.name')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{order.getContact()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('orders.list.details.total_weight')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          <Qty class="pl-0 pr-1" element-style="d-inline text-center col-12" :amount="order.getTotalBulk()" unit="kg" /><span>{{ $t('general.bulk') }}</span> + <Qty class="pl-0" element-style="d-inline" :amount="order.getTotalPacked()" :unit="$t('general.no_packing_bulk')" />
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.list.details.price')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Price :amount="order.getTotalPriceWithVat()" :currency="order.getCurrency()" />, {{$t('orders.list.details.items', {nr: order.getItemCollection().getSize()})}}
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.list.details.delivery_estimation')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <DayTimeEstimation :moment="order.getCycle().getDeliverOrder()" :hours="order.getDeliveryHours()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.list.details.comments')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          {{ order.getComment() ? order.getComment() : '-' }}
        </dd>
      </dl>
    </template>
    <template v-slot:footer>
      <Button @click="closeAndGo" class="mr-auto" :name="$t('orders.buttons.edit')" type="eye"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Price from "@/components/elements/Price";
import Status from "@/components/elements/Status";
import Button from "@/components/elements/Button";
import Qty from "@/components/elements/Qty";
import {DatesMixin} from "@/mixins/GeneralMixin";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";

export default {
  name: "OrderDetailsModal",
  components: {DayTimeEstimation, Qty, Button, Status, Modal, Price},
  mixins: [DatesMixin],
  props: {
    order: Object,
  },
  methods: {
    closeAndGo: function () {
      this.$router.push({name: 'general.breadcrumb.orders.edit', params: {'orderId': this.order.getId()}});
      this.$emit('closemodal');
    }
  }
}
</script>

<style scoped>
</style>