<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation"
                     :action="$t(confirmationType)"/>
  <OrderDetailsModal @closemodal="selectedOrder=null" v-if="selectedOrder" :order="selectedOrder"/>
  <div v-if="isOrderListRoute" class="row">
    <div class="col-md-12">
      <Card title="orders.list.title" :with-footer="false">
        <template v-slot:subtitle>
          <span v-if="activeCycle"
                class="d-block font-size-lg text-muted mb-2"> {{ $t('orders.list.subtitle', {order_batch: formatMomentDate(activeCycle.getOrderBatch())}) }}</span>
          <OrderCycleSelectInput @loaded="setCycles" :key="rerender" :disabled="isLoading || !hasCycles"
                                 class="font-size-base" v-model="selectedCycle"/>
        </template>
        <template v-slot:header>
          <div v-if="hasCycles" class="d-block justify-content-around">
            <div v-bind:class="{'d-flex':!isScreenLgWidth}" v-if="activeCycle && isActiveCycleSelected">
              <Button v-if="!isScreenLgWidth" class="mb-2 mt-3 ml-auto mr-auto"
                      @click="askConfirmation('orders.list.confirm.cycle_end')" name="orders.buttons.cycle_close"
                      type="delete"/>
              <Button v-else class="mb-0 mr-3" @click="askConfirmation('orders.list.confirm.cycle_end')"
                      name="orders.buttons.cycle_close" type="delete"/>
            </div>
            <div v-bind:class="{'d-flex':!isScreenLgWidth}" v-if="!activeCycle">
              <Button v-if="!isScreenLgWidth" class="mb-2 mt-3 ml-auto mr-auto" @click="startActiveCycle"
                      name="orders.buttons.cycle_start" type="confirm"/>
              <Button v-else class="mb-0 mr-3" @click="startActiveCycle" name="orders.buttons.cycle_start"
                      type="confirm"/>
            </div>
          </div>
          <div v-else class="d-block justify-content-around">
            <p>{{ $t('cycles.list.empty') }}</p>
          </div>
        </template>
        <template v-slot:default>
          <OrderTabs :key="selectedCycle" :new-orders="newOrders.getPagination().getTotal()"
                     :in-progress-orders="inProgressOrders.getPagination().getTotal()"
                     :delivered-orders="deliveredOrders.getPagination().getTotal()">
            <template v-slot:new_orders>
              <OrderTable @details="showOrderDetails" @page="getNewOrders" :pagination="newOrders.getPagination()"
                          :elements="newOrders.getItems()"></OrderTable>
              <div
                  class="card-footer border-light bg-white d-flex justify-content-around justify-content-lg-between align-items-center">
                <div class="align-self-center">
                  <Button v-if="canApproveOrders && isActiveCycleSelected" class="w-100 mb-2"
                          v-bind:disabled="newOrders.isEmpty()" @click="askConfirmation('orders.list.confirm.full')"
                          type="confirm" name="orders.buttons.confirm_partial"/>
                  <Button v-if="canUploadOrders" class="w-100 mb-2"
                          @click="$router.push({name: 'general.breadcrumb.orders.upload'})" type="upload"
                          name="orders.buttons.upload"/>
                  <Button v-bind:class="{'w-100': isActiveCycleSelected}" class="d-flex d-lg-none w-lg-auto mb-0"
                          v-bind:disabled="newOrders.isEmpty()" @click="askConfirmation('orders.list.confirm.delete')"
                          type="delete" name="orders.buttons.delete_all"/>
                </div>
                <Button class="w-50 w-lg-auto mb-0 d-none d-lg-flex" v-bind:disabled="newOrders.isEmpty()"
                        @click="askConfirmation('orders.list.confirm.delete')" type="delete"
                        name="orders.buttons.delete_all"/>
              </div>
            </template>
            <template v-slot:inprogress_orders>
              <OrderTable @details="showOrderDetails" @page="getInProgressOrders"
                          :pagination="inProgressOrders.getPagination()"
                          :elements="inProgressOrders.getItems()"></OrderTable>
            </template>
            <template v-slot:delivered_orders>

              <div class="row mb-4">
                <div class="col-4 col-lg-2">

                  <div><span>{{ $t('orders.list.filters.delivered_from') }}</span></div>
                  <DatePicker :class="{'error':v$.filters.delivered_from.$error}" inputFormat="dd-MM-yyyy"
                              v-model="filters.delivered_from" :clearable="true" class="form-control w-100"
                              :placeholder="''"/>

                </div>
                <div class="col-4 col-lg-2">

                  <div><span>{{ $t('orders.list.filters.delivered_to') }}</span></div>
                  <DatePicker :class="{'error':v$.filters.delivered_to.$error}" inputFormat="dd-MM-yyyy"
                              v-model="filters.delivered_to" :clearable="true" class="form-control w-100"
                              :placeholder="''"/>

                </div>
                <div class="col-4 col-lg-2">
                  <div><span>{{ $t('orders.list.filters.customer') }}</span></div>
                  <TextInput :reference="filters" @input="setCustomerFilter" :value="filters.customer" :placeholder="''"
                             v-bind:class="v$.filters.customer.$error && 'error'"/>
                </div>
                <div class="col-4 col-lg-2">
                  <div><span>{{ $t('orders.list.filters.farmer') }}</span></div>
                  <TextInput :reference="filters" @input="setFarmerFilter" :value="filters.farmer" :placeholder="''"
                             v-bind:class="v$.filters.farmer.$error && 'error'"/>
                </div>
                <div class="col-4 col-lg-2">
                  <div><span>&nbsp;</span></div>
                  <Button class="ml-auto mr-auto w-100 border-0" :class="{'btn-light': !filtersChanged}" @click="getDeliveredOrders(1)"
                          name="orders.buttons.filter" type="search"/>
                </div>

                <div class="col-4 col-lg-2">
                  <div><span>&nbsp;</span></div>
                  <Button class="ml-auto mr-auto w-100 border-0" @click="exportDeliveredOrders(filters)"
                          name="orders.buttons.download" type="download"/>
                </div>
              </div>

              <OrderTable @details="showOrderDetails" @page="getDeliveredOrders"
                          :pagination="deliveredOrders.getPagination()"
                          :elements="deliveredOrders.getItems()"></OrderTable>
            </template>
          </OrderTabs>
        </template>
      </Card>
    </div>
  </div>
  <router-view @update="init"/>
</template>

<script>
import Card from "@/components/elements/Card";
import OrderTabs from "@/components/order/backoffice/OrderTabs";
import OrderTable from "@/components/order/backoffice/OrderTable";
import Button from "@/components/elements/Button";
import Order from "@/entities/Order";
import OrderCollection from "@/entities/OrderCollection";
import Pagination from "@/entities/Pagination";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin} from "@/mixins/GeneralMixin";
import OrderDetailsModal from "@/components/order/backoffice/OrderDetailsModal";
import OrderCycleSelectInput from "@/components/order/backoffice/OrderCycleSelectInput";
import DatePicker from "vue3-datepicker";
import useVuelidate from "@vuelidate/core";
import TextInput from "@/components/elements/TextInput.vue";

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  name: "OrderList",
  components: {
    TextInput,
    DatePicker,
    OrderDetailsModal, ConfirmationModal, OrderTable, OrderTabs, Card, Button, OrderCycleSelectInput
  },
  mixins: [LoadingModalMixin, ConfirmationModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin],
  data: function () {
    return {
      newOrders: new OrderCollection(),
      inProgressOrders: new OrderCollection(),
      deliveredOrders: new OrderCollection(),
      canUpload: true,
      hasCycles: false,
      selectedOrder: null,
      selectedCycle: 0,
      filtersChanged: false,
      filters: {
        delivered_from: '',
        delivered_to: '',
        status: '',
        customer: '',
        farmer: ''
      }
    }
  },
  validations: {
    filters: {
      delivered_from: {},
      delivered_to: {},
      customer: {},
      farmer: {}
    }
  },
  beforeMount() {
    this.selectedCycle = this.activeCycle ? this.activeCycle.getId() : 0;
  },
  computed: {
    rerender: function () {
      return this.$store.state.rerender;
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    },
    isActiveCycleSelected: function () {
      if (!this.activeCycle) {
        return true;
      }

      return this.selectedCycle == this.activeCycle.getId();
    },
    areOrdersLoading: function () {
      return !this.newOrders.isLoaded() || !this.inProgressOrders.isLoaded() || !this.deliveredOrders.isLoaded();
    },
    isEverythingDelivered: function () {
      return this.newOrders.isLoaded() && this.newOrders.isEmpty() &&
          this.inProgressOrders.isLoaded() && this.inProgressOrders.isEmpty();
    },
    isOrderListRoute: function () {
      return this.$router.currentRoute.value.name === 'general.breadcrumb.orders.list';
    },
  },
  watch: {
    selectedCycle: {
      handler: function () {
        this.init();
      },
    },
    activeCycle: {
      handler: function (activeCycle) {
        if (activeCycle !== null && this.selectedCycle === 0) {
          this.selectedCycle = activeCycle.getId();
        }
      }
    },
    filters: {
      handler: function () {
        this.filtersChanged = true;
      },
      deep: true
    },
  },
  methods: {
    goToUploadOrders: function () {
      if (!this.canUpload) {
        return;
      }
      this.canUpload = false;
      this.$router.push({name: 'general.breadcrumb.orders.upload'});
    },
    onLoadingComplete() {
      this.canUpload = false;
      if (this.initialise) {
        this.init();
      }
    },
    confirmed() {
      switch (this.confirmationType) {
        case "orders.list.confirm.partial":
          this.approveOrders();
          break;
        case "orders.list.confirm.full":
          this.approveAllOrders();
          break;
        case "orders.list.confirm.delete":
          this.deleteOrders();
          break;
        case "orders.list.confirm.cycle_end":
          this.endActiveCycle();
          break;
      }
      this.closeConfirmation();
    },
    reset() {
      this.newOrders.unloadAndEmpty();
      this.inProgressOrders.unloadAndEmpty();
      this.deliveredOrders.unloadAndEmpty();
    },
    init() {
      this.reset();
      this.getNewOrders();
      this.getInProgressOrders();
      this.getDeliveredOrders();
    },
    getNewOrders(pageNr = 1) {
      let filter = {
        status: Order.getNewStatuses(),
        cycle: [this.selectedCycle],
      };

      this.newOrders = new OrderCollection();
      this.hydrateOrders(this.newOrders, filter, pageNr)
    },
    getInProgressOrders(pageNr = 1) {
      let filter = {
        status: Order.getInProgressStatuses(),
        cycle: [this.selectedCycle],
      };

      this.inProgressOrders = new OrderCollection();
      this.hydrateOrders(this.inProgressOrders, filter, pageNr)
    },
    getDeliveredOrders(pageNr = 1) {
      let filter = {
        status: !this.filters.status ? Order.getDeliveredStatuses() : [this.filters.status],
        delivered_from: this.filters.delivered_from,
        delivered_to: this.filters.delivered_to,
        customer: this.filters.customer,
        farmer: this.filters.farmer
      };

      this.deliveredOrders = new OrderCollection();
      this.hydrateOrders(this.deliveredOrders, filter, pageNr)
    },

    hydrateOrders(orders, filter, pageNr = 1) {
      let that = this;
      let localOrders = new OrderCollection();
      let filterParams = {
        page: pageNr,
      };

      if (typeof filter.status !== "undefined") {
        filterParams.status = filter.status;
      }
      if (typeof filter.delivered_from !== "undefined" && filter.delivered_from) {
        filterParams.delivered_from = this.formatDateEN(filter.delivered_from);
      }
      if (typeof filter.delivered_to !== "undefined" && filter.delivered_to) {
        filterParams.delivered_to = this.formatDateEN(filter.delivered_to);
      }
      if (typeof filter.customer !== "undefined" && filter.customer) {
        filterParams.customer = filter.customer;
      }
      if (typeof filter.farmer !== "undefined" && filter.farmer) {
        filterParams.farmer = filter.farmer;
      }

      if (typeof filter.cycle !== "undefined") {
        filterParams.cycle = filter.cycle;
      }

      this.queueJob();
      this.axios.get(this.$store.state.config.getOrdersUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            localOrders.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (order) {
              localOrders.push(new Order(order));
            });

            localOrders.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (localOrders.isLoaded()) {
          orders.setPagination(localOrders.getPagination());
          localOrders.getItems().forEach(order => orders.push(order));
          orders.loaded();
        }
        that.filtersChanged = false;
        that.completeJob();
      });
    },

    async exportDeliveredOrders(filter) {
      this.queueJob();

      try {

        let filterParams = {
          status: !this.filters.status ? Order.getDeliveredStatuses() : [this.filters.status],
        };
        if (typeof filter.delivered_from !== "undefined" && filter.delivered_from) {
          filterParams.delivered_from = this.formatDateEN(filter.delivered_from);
        }
        if (typeof filter.delivered_to !== "undefined" && filter.delivered_to) {
          filterParams.delivered_to = this.formatDateEN(filter.delivered_to);
        }
        if (typeof filter.customer !== "undefined" && filter.customer) {
          filterParams.customer = filter.customer;
        }
        if (typeof filter.farmer !== "undefined" && filter.farmer) {
          filterParams.farmer = filter.farmer;
        }

        if (typeof filter.cycle !== "undefined") {
          filterParams.cycle = filter.cycle;
        }

        const response = await this.axios.get(this.$store.state.config.getOrdersExportUri(), {params: filterParams});
        const date = new Date();
        this.$store.dispatch('download', {
          data: response.data,
          type: 'text/csv;charset=utf-8;',
          name: 'verde-orders-export-' + date.toLocaleDateString() + '.csv',
        }, {root: true});

        this.completeJob();
        return Promise.resolve('done')
      } catch (e) {
        this.completeJob();
        return Promise.reject(e)
      }
    },

    approveOrders() {
      let that = this;
      this.newOrders.getItems().forEach(function (order) {
        that.queueJob();
        that.axios.put(that.$store.state.config.getApproveOrdersUri(order.getId())).catch(
            error => {
              console.warn(error);
            }
        ).finally(function () {
          that.completeJob(true);
          that.$store.commit("refreshActiveCycle");
        });
      });
    },
    startActiveCycle: function () {
      let that = this;
      if (this.activeCycle) {
        return;
      }

      this.queueJob();
      this.axios.put(this.$store.state.config.getStartActiveCycleUri()).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit("refreshActiveCycle");
        that.completeJob(true);
      });
    },
    approveAllOrders() {
      let that = this;
      this.queueJob();
      that.axios.put(that.$store.state.config.getApproveAllOrdersUri()).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit("refreshActiveCycle");
        that.completeJob(true);
      });
    },
    endActiveCycle: function () {
      let that = this;
      if (!this.selectedCycle) {
        return;
      }

      this.canUpload = true;
      this.queueJob();
      this.axios.put(this.$store.state.config.getEndActiveCycleUri()).then(
          () => {
            that.goToUploadOrders();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit("refreshActiveCycle");
        that.completeJob(true);
      });
    },
    deleteOrders() {
      let that = this;
      this.newOrders.getItems().forEach(function (order) {
        that.queueJob();
        that.axios.delete(that.$store.state.config.getOrdersDeleteUri(order.getId())).catch(
            error => {
              console.warn(error);
            }
        ).finally(function () {
          that.completeJob(true);
        });
      });
    },
    setCycles(cycles) {
      this.hasCycles = cycles.length > 0;
    },
    showOrderDetails(order) {
      if (!this.isActiveCycleSelected) {
        return;
      }

      if (!this.isScreenLgWidth) {
        this.selectedOrder = order;
      } else {
        this.$router.push({name: 'general.breadcrumb.orders.edit', params: {'orderId': order.getId()}})
      }
    },

    setCustomerFilter: function (filter, value) {
      filter.customer = value
      this.v$.filters.customer.$touch();
      this.filtersChanged = true;
    },

    setFarmerFilter: function (filter, value) {
      filter.farmer = value
      this.v$.filters.farmer.$touch();
      this.filtersChanged = true;
    },
  }
}
</script>

<style>


</style>
