<template>
  <Tabs type="bottom-justified-divided">
    <template v-slot:header>
      <Tab :active="inProgressOrders === 0" :name="$t('orders.list.new', {nr: newOrders})" location="new" icon="menu3"></Tab>
      <Tab :active="inProgressOrders > 0" :name="$t('orders.list.inprogress', {nr: inProgressOrders})" location="inprogress" icon="spinner2"></Tab>
      <Tab :active="false" :name="$t('orders.list.delivered', {nr: deliveredOrders})" location="delivered" icon="car"></Tab>
    </template>
    <template v-slot:default>
      <div v-bind:class="{'show active': inProgressOrders === 0}" class="tab-pane fade" id="new">
        <slot name="new_orders"></slot>
      </div>

      <div v-bind:class="{'show active': inProgressOrders > 0}" class="tab-pane fade" id="inprogress">
        <slot name="inprogress_orders"></slot>
      </div>

      <div class="tab-pane fade" id="delivered">
        <slot name="delivered_orders"></slot>
      </div>
    </template>
  </Tabs>
</template>

<script>
import Tabs from "@/components/elements/Tabs";
import Tab from "@/components/elements/Tab";
export default {
  name: "OrderTabs",
  components: {Tab, Tabs},
  props: {
    newOrders: {
      type: Number,
      default: 0,
    },
    inProgressOrders: {
      type: Number,
      default: 0,
    },
    deliveredOrders: {
      type: Number,
      default: 0,
    },
  }
}
</script>

<style scoped>

</style>
