<template>
  <ul class="nav nav-tabs align-items-center" v-bind:class="styling">
    <slot name="header"></slot>
  </ul>

  <div class="tab-content">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "Tabs",
  props: {
    type: String
  },
  computed: {
    styling: function () {
      switch (this.type) {
        case "bottom-justified":
          return "nav-justified nav-tabs-bottom"
        case "bottom-justified-divided":
          return "nav-justified nav-tabs-bottom border-bottom-0"
        default:
          return "";
      }
    }
  }
}
</script>

<style scoped>

</style>