import LoadableCollection from "@/entities/LoadableCollection";

export default class OrderCollection extends LoadableCollection {

    getNewOrders() {
        return this.items.filter(order => order.isNew())
    }
    getInProgressOrders() {
        return this.items.filter(order => order.isInProgress());
    }
    getDeliveredOrders() {
        return this.items.filter(order => order.isDelivered());
    }
}