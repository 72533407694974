<template>
  <li class="nav-item">
    <a v-bind:href="'#'+location" class="nav-link d-block font-size-lg" v-bind:class="active && 'active'" data-toggle="tab">
      <span v-show="icon" v-bind:class="iconClass" class="d-block d-sm-inline mr-sm-2" />
      <span class="text-nowrap">
      {{ name }}
      </span>
    </a>
  </li>
</template>

<script>
export default {
  name: "Tab",
  props: {
    name: String,
    location: String,
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    }
  },
  computed: {
    iconClass: function () {
      return "icon-" + this.icon;
    }
  }
}
</script>

<style scoped>

</style>