<template>
  <div>
  <Table @page="onPage" :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
        {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="elements.length">
      <TableElement @click="$emit('details', element)"
                    :class="element.getId() % 2 == 0 ? 'even': 'odd'" v-for="element in elements"
                    v-bind:key="element.getId()">
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ element.getId() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Call @click.stop :telephone="element.getTelephone()" />
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            {{ element.getContact() }}
            <a :href="element.getInvoice()[0].url" @click="$event.stopPropagation()" target="_blank" v-if="element.getInvoice() && element.getInvoice().length">
              <i class="icon-attachment ml-2" ></i>
            </a>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Price :amount="element.getTotalPriceWithVat()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ formatMomentDate(element.getCycle().getDeliverOrder()) }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Status class="text-wrap" :status="element.getStatus()" />
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="!elements.length" >
      <TableElement :size="headers.length" :empty-message="$t('orders.list.empty')" />
    </template>
  </Table>
  </div>
</template>

<script>
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Status from "@/components/elements/Status";
import Price from "@/components/elements/Price";
import Call from "@/components/elements/Call";
import {ScreenSizeMixin, DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "OrderTable",
  components: {Call, Price, TableElement, TableElementItem, Table, Status},
  mixins: [ScreenSizeMixin, DatesMixin],
  emits: ['page', 'details'],
  props: {
    elements: Array,
    pagination: Object,
  },
  data: function() {
    return {
      allHeaders: [
          'orders.list.details.id',
          'orders.list.contact',
          'orders.list.name',
          'orders.list.total',
          'orders.list.delivery_estimation',
          'orders.list.status',
      ],
      responsiveHeaders: [
        'orders.list.contact',
        'orders.list.name',
        'orders.list.status',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    }
  },
  methods: {
    onPage(pageNr) {
      this.$emit('page', pageNr);
    }
  }
}
</script>

<style scoped>

</style>
